html {
	font-size: 62.5%; // 16px
}

body {
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	background-color: $blue-dark;
	font-size: 1.3rem;
	color: $text-color;
	overflow-x: hidden;
}
