// ********------------ Form  ------------********

select {
	cursor: pointer;
}

// ************* Form group *************
.form-group {
	position: relative;

	label,
	&__preview_label {
		font-weight: bold;
	}

	&__disabled {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 10;
		cursor: not-allowed;
		background-color: rgba(238, 238, 238, 0.26);
	}

	&.has-error {
		.ant-input {
			border: 1px solid $red;
		}
	}
}

// ************* Input *************

.form-control {
	color: $gray-dark;
	font-weight: 600;
	border-radius: 2px;
	height: 4rem;
	font-size: 1.2rem;
	background-color: $gray-white-light;

	&:focus {
		border-color: $blue;
		background-color: $gray-white-light;
	}

	&:disabled {
		background-color: $white;
		border: 1px solid $gray-white-light;
	}

	&::-webkit-input-placeholder,
	&:-ms-input-placeholder,
	&::placeholder {
		color: $placeholder-gray;
	}
}

.input-group-text {
	min-width: 6rem;
	font-size: 1.3rem;
	@include flexCenter;
	font-weight: bold;
}

.ant-input[disabled] {
	color: rgba(0, 0, 0, 0.5);
}

// ************* Input Error *************
.has-error .form-control,
.has-error .form-control:focus {
	margin-bottom: 0.2rem;
}

.has-error .form-control-sufix,
.has-error .form-control-sufix:focus {
	margin-bottom: 0px;
}
.has-error .input-group-sufix {
	margin-bottom: 1rem;
}

.input-error {
	color: $red;
	font-weight: bold;
	font-size: 1.2rem;
	display: inline-block;
	margin-top: 0.9rem;
	&-sufix {
		margin-top: 10px;
	}
}

.input-warning {
  color: $warning;
	font-weight: bold;
	font-size: 1.2rem;
	display: block;
	margin-top: 0.9rem;
	&-sufix {
		margin-top: 10px;
	}
}

// ************* Input Info *************
.input-info {
	color: $text-color;
	font-weight: bold;
	font-size: 1.2rem;
	display: inline-block;
	margin-top: 0.9rem;
	&-sufix {
		margin-top: 10px;
	}
}

// ************* Form preview *************
.form-preview {
	.form-group {
		margin-bottom: 0.4rem;
	}

	.col-form-label {
		padding: 0 1.5rem;
	}

	.form-control {
		height: 2.2rem;
	}

	textarea {
		min-height: 6rem !important;
		@include scrollModal;
	}

	.form-control:disabled {
		border: 1px solid transparent;
	}
}
