.btn.focus,
.btn:focus {
	box-shadow: none !important;
}

.btn-secondary {
	@include buttonBase();
}

.btn {
	// ********** Button link **********
	&__link {
		&:link,
		&:visited {
			color: $white;
			background-color: $navy;
			border: none;
			height: 3.2rem;
			font-size: 1.4rem;
			padding: 0 1.5rem;
			@include flexCenter;
			border-radius: 2px;
		}

		&:active {
			box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.12);
		}

		&--nav {
			&:link,
			&:visited {
				background-color: $gray-medium;
			}
		}
		&--active {
			&:link,
			&:visited {
				background-color: $navy;
			}
		}

		&--outline {
			&:link,
			&:visited {
				background: transparent;
				border: 1px solid $gray-medium-light;
				color: $blue-dark;
			}
			&:hover {
				background: transparent;
			}
			&:active {
				box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.08);
			}
		}

		&--disabled {
			&:link,
			&:visited {
				cursor: not-allowed;
				pointer-events: none;
				background-color: $gray-medium-light;
			}
		}
	}

	// ********** Sign in **********
	&__signin {
		@include buttonBase($navy, $navy);
		font-size: 1.3rem;
		height: 4.8rem;
		font-weight: 600;
		text-transform: uppercase;
	}

	// ********** Signout **********
	&__signout {
		@include buttonBase($navy, $navy);
		font-size: 1.4rem;
	}

	// ********** Navbar header **********
	&__menu {
		@include buttonBase();
		margin-left: 0 !important;
		padding: 0.4rem 1.3rem;
	}
	// ********** Group Append **********
	&__group-append {
		height: 100%;
	}

	// ********** Clear **********
	&__clear {
		height: 2rem;
		width: 2rem;
		padding: 0rem;
		border-radius: 50%;
		@include flexCenter;
	}

	// ********** Sign In Language Change **********
	&__language_sign_in {
		position: absolute;
		right: 20px;
		top: 20px;
		color: $white;
		opacity: 0.5;
		background: none;
		border: none;

		&:hover {
			color: $blue;
		}
	}
}

// Navbar top button
.minimalize-styl-2 {
	margin: 0 0 0 1.5rem;
}
