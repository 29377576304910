.info-title {
	background-color: $gray-very-light;
	padding: 1rem 2rem;
	border-radius: 5px;

	&__header {
		color: $text-color;
		&__icon {
			font-size: 1.7rem;
		}
	}
}
