// ---------------  Confirm alert ---------------
.react-confirm-alert {
	transform: translateY(-5rem);
}

.react-confirm-alert-overlay {
	z-index: 9999;
	background: rgba(255, 255, 255, 0.4);
}

.react-confirm-alert-button-group {
	button {
		transition: all 0.1s ease-in-out;
		&:hover {
			background: $navy;
		}
	}
}
