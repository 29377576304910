/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.7.1
 *
*/
// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

// Variables, Mixins
@import 'inspinia_styles/variables';
@import 'inspinia_styles/mixins';

// INSPINIA Theme Elements
@import 'inspinia_styles/typography';
@import 'inspinia_styles/navigation';
@import 'inspinia_styles/top_navigation';
@import 'inspinia_styles/buttons';
@import 'inspinia_styles/badges_labels';
@import 'inspinia_styles/elements';
@import 'inspinia_styles/sidebar';
@import 'inspinia_styles/base';
@import 'inspinia_styles/pages';
@import 'inspinia_styles/chat';
@import 'inspinia_styles/metismenu';
@import 'inspinia_styles/spinners';

// Landing page styles
@import 'inspinia_styles/landing';

// RTL Support
@import 'inspinia_styles/rtl';

// For demo only - config box style
@import 'inspinia_styles/theme-config';

// INSPINIA Skins
@import 'inspinia_styles/skins';
@import 'inspinia_styles/md-skin';

// Media query style
@import 'inspinia_styles/media';

// Clear layout on print mode
@media print {
	nav.navbar-static-side {
		display: none;
	}
	body {
		overflow: visible !important;
	}

	#page-wrapper {
		margin: 0;
	}
}

// Custom style
// Your custom style to override base style
@import 'custom_styles/dir_custom';
