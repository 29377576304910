.platforms-table {
	color: $text-color;

	&__header-cell {
		text-align: center;
		padding: 1rem;
	}
	&__header-options {
		padding: 1rem;
	}
	&__row-name {
		padding: 0.5rem;
	}
	&__cell {
		padding: 0.5rem;
		text-align: center;
	}
	&__row-dark {
		background-color: $blue-pastel;
	}
	&__input {
		margin-top: 1rem;
	}
}
