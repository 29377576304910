.sortable-list,
.cards-list {
	padding: 8px 4px 0 0;
	margin: 0 -5px 4px;
	display: flex;
	flex-wrap: wrap;
	width: calc(100% + 10px);
	overflow-y: auto;
	@media (min-width: 993px) {
		padding: 0;
	}

	&::after {
		content: '';
		display: block;
		clear: both;
	}

	&.drag {
		.sortable-list__li:hover {
			.item__delete {
				opacity: 0;
			}
		}
	}
}

.sortable-list__li,
.cards-list__li {
	list-style: none;
	padding: 0 5px;
	flex: 0 0 50%;
	width: 50%;
	margin-bottom: 10px;
	user-select: none;
	@media (min-width: 576px) {
		flex: 0 0 25%;
		width: 25%;
	}
	@media (min-width: 768px) {
		flex: 0 0 25%;
		width: 25%;
	}
	@media (min-width: 993px) {
		flex: 0 0 20%;
		width: 20%;
	}
	@media (min-width: 1170px) {
		flex: 0 0 16.6666%;
		width: 16.6666%;
	}
	@media (min-width: 1400px) {
		flex: 0 0 14.285%;
		width: 14.285%;
	}
	@media (min-width: 1700px) {
		flex: 0 0 12.5%;
		width: 12.5%;
	}

	&:hover {
		.item__delete {
			opacity: 1;
		}
	}

	.item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		min-height: 90px;
		height: 100%;
		padding: 5px;
		background: #f8f8f8;
		text-align: center;
		font-size: 1.3rem;
		line-height: 1.3;
	}

	.item__img {
		display: flex;
		width: 100%;
		height: 60px;
		justify-content: center;
		align-items: center;

		img {
			max-height: 100%;
			max-width: 100%;
			height: auto;
			width: auto;
		}
	}

	.item__delete {
		display: block;
		outline: none;
		position: absolute;
		z-index: 1;
		background-color: #ff4d4f;
		color: #fff;
		cursor: pointer;
		border: none;
		padding: 0;
		text-align: center;
		transition: opacity 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
			background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		@media (max-width: 992px) {
			top: -8px;
			right: -8px;
			width: 18px;
			height: 18px;
			border-radius: 50%;
		}
		@media (min-width: 993px) {
			top: 5px;
			left: 50%;
			transform: translateX(-50%);
			width: 66px;
			height: 26px;
			border-radius: 3px;
			opacity: 0;
		}

		&:hover {
			background-color: #ff7875;
		}

		i {
			font-size: 14px;
		}
		.btn-icon {
			@media (min-width: 993px) {
				display: none;
			}
		}

		.btn-txt {
			display: none;
			@media (min-width: 993px) {
				display: inline;
			}
		}
	}

	.item__title {
		display: block;
		margin: 4px 0 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		color: #212121;
		&__link {
			&:hover {
				color: $navy-hover;
			}
		}
	}

	.item__category {
		display: block;
		margin: 4px 0 0;
		color: #848484;
	}

	.item__vodType {
		display: block;
		margin: 4px 0 0;
		color: #848484;
	}

	.item__text-container {
		margin: 40px 0 10px 0;
	}
}
