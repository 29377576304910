// *******------------ Alert ------------********

.alert {
	background-color: $navy;
	color: $white;
	animation-duration: 400ms !important;

	&__login {
		width: 40rem;
	}
}
