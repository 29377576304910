// ********------------ Navigation ------------********
.navigation {
	@media (min-width: 768px) {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		overflow: hidden;
		overflow-y: auto;
		padding-bottom: 3rem;
	}
}

.nav-header {
	background-image: none;
	background-color: $blue-dark;
	width: 100%;
}

.nav-header {
	padding: 15px 25px 10px 30px;
}

.nav-header a {
	color: $white;
}

.nav-header .text-muted {
	color: $white;
}

.nav > li.active {
	border-left: 4px solid $blue-darker;
	background-color: $white-light;
}

.nav > li > a {
	color: $white;
}

.nav > li.active > a {
	color: $nav-links;
}

.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus {
	background-color: $blue-darker;
}

.nav {
	&__item {
		width: 100%;

		&--section {
			padding: 14px 20px 5px 25px;
			border-bottom: 1px solid $blue-light;
			margin-bottom: 1rem;
		}
	}
	&__link {
		&:link,
		&:visited {
			position: relative;
			display: inline-block;
			width: 100%;
		}

		&:link::after,
		&:visited::after {
			position: absolute;
			content: '';
			display: inline-block;
			top: 0;
			left: 0;
			width: 0.4rem;
			height: 100%;
			background-color: transparent;
		}

		&--active {
			background-color: $blue-darker;

			&:link::after {
				background-color: $blue-darker;
			}
		}
	}
}

// ------------------ User ------------------
.user {
	margin-top: 10px;

	&__name,
	&__profile {
		margin-bottom: 0 !important;
		color: $white;
	}

	&__profile {
		font-weight: 300;
	}

	&__name {
		margin-bottom: 3px;
	}
}
