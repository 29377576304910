// Basic Colors
$navy: #4e99e8; // Primary color
$navy-hover: #1890ff; // Tab hover color
$dark-gray: #c2c2c2; // Default color
$blue: #4e99e8; // Success color
$blue-light: #53afe0;
$blue-dark: #293846;
$blue-darker: #2f4051;
$blue-pastel: #ebf5ff;
$blue-pastel-dark: #d7e9fc;
$white-light: #bee4fd;
$lazur: #23c6c8; // Info color
$warning: #f8ac59; // Warning color
$red: #ed5565; // Danger color
$pink: #fb4184;
$pink_light: rgba($pink, 0.9);
$white: #ffff;
$black: #000;

// Custom colors
$green: #13ce66;
$text-color: #727272; // Body text
$gray-light: #5a5a5a;
$gray-dark: #444;
$gray-very-light: rgb(221, 221, 221);
$gray-medium-light: #eeeeee;
$gray-white-light: #f7f7f7;
$gray-border: #bfcbd9;

// Various colors
$text-color: #676a6c; // Body text
$nav-links: #505050;
$gray: #f3f3f4;
$gray-medium: #999c9e; // Background wrapper color
$light-gray: #d1dade; // Default label, badge
$label-badge-color: #5e5e5e;
$light-blue: #f3f6fb;
$yellow: #fdb913;

// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

// IBOX colors ( default panel colors)
$border-color: rgba(231, 234, 236, 0.747); // IBox border
$ibox-title-bg: #ffffff; // IBox Background header
$ibox-content-bg: #ffffff; // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2f4050;
$nav-text-color: #a7b1c2;

// Form
$placeholder-gray: #bfbfbf;

//  ---- EXPORTED COLOR VARIABLES TO JS ----
:export {
	blueDark: $blue-dark;
}
