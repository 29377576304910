.ibox {
	border-radius: 0;
	transition: all 0.3s ease-in-out;
}

.ibox-content {
	word-break: break-word;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-top: 2px solid $navy;
}

.ibox-title {
	display: flex;
	justify-content: space-between;
	border-color: $navy;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.custom-ibox-title {
	display: flex;
	justify-content: space-between;
	margin: 0 -20px 15px;
	padding: 0 15px 7px;
	border-bottom: 1px solid rgba(231, 234, 236, 0.747);
}

.ibox-title + .ibox-content {
	border-top: 1px solid rgba(231, 234, 236, 0.747);
}

.ibox-content-rounded {
	border-radius: 0;
}

// If product is loading
.ibox-content.sk-loading {
	z-index: 1000;

	.sk-loading-box {
		position: relative;
		z-index: -1;
	}
}

.ibox-content--no-bg.sk-loading:after {
	background-color: rgba(255, 255, 255, 0.3);
}
