.icon {
	&--logo {
		height: 12rem;
	}
	&--table_edit {
		font-size: 2rem;
		&:hover {
			cursor: pointer;
		}
	}
	&--table_delete {
		font-size: 2rem;
		color: $red;

		&:hover {
			cursor: pointer;
		}
	}
	&--table_show {
		font-size: 2rem;
	}
}
