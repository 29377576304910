.sign_in_alert {
	background-color: $red;
	border-radius: 5px;
	color: $white;
	width: 40rem;

	&__container {
		display: flex;
		margin: 1.7rem 0 1rem 2.5rem;
	}

	&__title {
		margin-top: 1rem;
		color: $white;
		font-size: 1.7rem;
	}
	&__message {
		font-size: 1.4rem;
	}
	&__icon {
		font-size: 3.5rem;
		margin-right: 2.5rem;
	}
}
