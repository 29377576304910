// ---------------  Buttons ---------------
@mixin buttonBase($color: $navy, $hoverColor: $navy) {
	display: inline-block;
	color: $white;
	background-color: $color;
	border: none;
	height: 3.2rem;
	font-size: 1.4rem;
	padding: 0 1.5rem;
	border-radius: 2px;

	&:disabled {
		cursor: not-allowed;
	}

	&:focus {
		outline: none;
	}

	&:active {
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.15) !important;
		background-color: $hoverColor !important;
	}

	&:hover {
		background-color: $hoverColor;
	}
}
