.reset-password {
	min-height: 100vh;
	position: relative;
	background-color: $blue-dark;
	@include flexCenter;
	flex-direction: column;

	&__content {
		width: 40rem;
		// min-height: 40rem;
		padding: 3.6rem 5rem 4rem 5rem;
		border-radius: 0;
		background-color: $blue-darker;
		text-align: center;
	}

	&--email-success {
		color: $green;
	}
	&--email-primary {
		color: $white;
		opacity: 0.6;
	}

	&--new-password-success {
		color: $green;
	}

	&--new-password-error {
		color: $red;
	}

	&__screen {
		@include flexHorizontal;
		position: relative;
		margin: 1rem;
		border-radius: 0;
		box-shadow: 5px 8px 20px 8px #00000024;
	}

	&__header {
		color: $white;
		opacity: 0.5;
	}

	&__spinner-container {
		text-align: center;
	}

	&__spinner {
		margin-top: 4rem;
	}

	&__content-text {
		margin: 3rem 0;
	}
}
