// ---------------  Scroll ---------------
@mixin scroll {
	&::-webkit-scrollbar {
		width: 0.8rem;
	}

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-thumb {
		border-radius: 50px;
		border-radius: 50px;
	}

	&::-webkit-scrollbar-track {
		background-color: rgba(209, 218, 222, 0.534);
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(83, 175, 224, 0.718);
	}
}

@mixin scrollModal {
	@include scroll;
	&::-webkit-scrollbar {
		width: 0.2rem;
	}
	&::-webkit-scrollbar-track {
		background-color: #dcdcdc;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #4a4a4a7c;
	}
	&::-webkit-scrollbar {
		width: 0.3rem;
	}
}
