// ---------- Checkbox square ----------
.checkbox-square {
	&__label,
	&__label_light {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	&__label {
		margin-bottom: 0;
	}

	&__box {
		@include flexVertical;
		align-items: flex-start;
		flex-direction: column;
	}

	&__checkbox {
		display: none;
	}

	&__checkbox:checked + &__shape {
		opacity: 1;
		background-color: $navy;
		border: 1px solid $navy;
	}

	&__checkbox:checked + &__icon {
		color: $white;
	}

	&__shape {
		position: relative;
		@include flexCenter;
		height: 20px;
		width: 20px;
		border-radius: 2px;
		border: 1px solid $dark-gray;
		cursor: pointer;
	}

	&__icon {
		color: $white;
	}
}

// ---------- Checkbox circle ----------
.checkbox-circle {
	&__label {
		display: flex;
		align-items: center;
	}

	&__checkbox {
		display: none;
	}

	&__checkbox:checked + &__shape::after {
		opacity: 1;
	}

	&__shape {
		position: relative;
		display: inline-block;
		height: 16px;
		width: 16px;
		border: 1px solid $dark-gray;
		border-radius: 50%;
		cursor: pointer;

		&::after {
			opacity: 0;
			position: absolute;
			border-radius: 50%;
			top: 2px;
			left: 2px;
			content: '';
			display: inline-block;
			background-color: #53afe0;
			height: 10px;
			width: 10px;
		}
	}
}

// ---------- Checkbox switch ----------
.checkbox-switch {
	&__checkbox {
		opacity: 0;
	}

	&__label {
		width: 46px;
		height: 26px;
		position: relative;
		background-color: $white;
	}

	&__shape {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 2px solid $dark-gray;
		border-radius: 13px;
		cursor: pointer;

		&::after {
			position: absolute;
			content: '';
			left: 2px;
			top: 1px;
			display: inline-block;
			width: 20px;
			height: 20px;
			background-color: $dark-gray;
			border-radius: 50%;
			transition: all 0.2s;
		}
	}

	&__checkbox:checked + &__shape {
		border: 2px solid $navy;
	}
	&__checkbox:checked + &__shape::after {
		left: 20px;
		background-color: $navy;
	}
}
