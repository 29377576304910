.btn-table-select {
	margin-right: 10px;
	margin-bottom: 10px;

	&.is-invalid {
		.ant-select-selection {
			border-color: $red;
		}
	}
}
