.title__upload {
	font-size: 14px;
	margin: 0 0 7px;
	padding: 0;
	text-overflow: ellipsis;
}

// ************* Image preview *************
.upload-image-preview-wrapper {
	margin-right: 8px;
    margin-bottom: 8px;
}

.upload-image-preview {
	height: 10.2rem;
	width: 10.2rem;
	border: 1px dashed #d9d9d9;
	border-radius: 4px;
	background-color: #fafafa;

	img {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
}
