body #page-wrapper {
	transition: all 0.2s ease-in-out;
}
body.mini-navbar #page-wrapper {
	margin: 0 0 0 7rem;
}

.wrapper-hide {
	display: none;
}
