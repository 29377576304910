.filepond--root {
	font-size: 1.5rem;
	height: 8rem;
}

.filepond--panel-root {
	background-color: $dark-gray;
}

.filepond--drop-label {
	height: 5rem;
}
