// ---------- Radio button ----------
.radio-button {
	&__checkbox {
		display: none;
	}

	&__checkbox:checked + &__shape {
		background-color: $navy;
		border: 1px solid $navy;
		color: $white;
	}

	&__shape {
		display: inline-block;
		height: 3.2rem;
		font-size: 1.4rem;
		padding: 1rem 1.5rem;
		@include flexCenter;
		border-radius: 3px;
		border: 1px solid transparent;
		cursor: pointer;
	}
}
