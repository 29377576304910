.ReactTable {
	border: 1px solid transparent;
}

.ReactTable .rt-table {
	margin-bottom: 15px;
	font-size: 1.3rem;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
	border-right: 1px solid transparent;

	&:focus {
		outline: none;
	}
}

.ReactTable .rt-thead.-header {
	box-shadow: none;
}

.ReactTable .rt-thead .rt-resizable-header-content {
	text-align: left;
	font-weight: bold;
}

.ReactTable .rt-thead .text-center .rt-resizable-header-content {
	text-align: center;
}

.ReactTable .rt-th {
	font-weight: bold;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
	box-shadow: inset 0 3px 0 0 $navy;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
	box-shadow: inset 0 -3px 0 0 $navy;
}

.ReactTable .-pagination {
	box-shadow: 0 0 15px 0 transparent;
	border-top: 2px solid transparent;

	@include mediaQuery(57em) {
		@include flexColumn;
		margin-top: 1rem;
	}
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
	@include mediaQuery(57em) {
		margin-top: 1rem;
	}
}

.ReactTable .-pagination .-previous {
	@include mediaQuery(57em) {
		order: 1;
	}
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
	-webkit-appearance: none;
	cursor: pointer;
	padding: 8px 14px;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-tbody .rt-td {
	padding: 7px 10px;
}

.ReactTable .rt-tbody .scroll-cell {
	overflow: hidden;
	overflow-x: auto;
	text-overflow: clip;
}

.ReactTable .rt-noData {
	@include mediaQuery(57.13em) {
		top: 31%;
	}
}

// ------ Resizer ------
.ReactTable .rt-resizer {
	display: none;
}

// ------ Loading ------
.ReactTable .-loading-inner,
.ReactTable .-loading > div {
	color: transparent !important;
}
.ReactTable .-loading.-active > div {
	position: relative;
	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: -5rem;
		left: 47%;
		transform: translate(-50%, -50%);
		height: 6rem;
		width: 6rem;
		border: 3px solid $navy;
		border-top-color: transparent;
		border-radius: 50%;
		animation: spinner 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
	}
}

// ------ No data ------
.ReactTable .rt-noData {
	border: 1px solid rgba(0, 0, 0, 0.05);
}

// ------ Checkbox ------
.ReactTable .rt-thead .rt-th input[type='checkbox'],
.ReactTable .rt-tbody .rt-td input[type='checkbox'] {
	position: relative;
	left: -3px;
	appearance: none;
	border: 1px solid $navy;
	border-radius: 2px;
	height: 16px;
	width: 16px;
	position: relative;
	cursor: pointer;

	&:focus {
		outline: none;
	}

	&::after {
		position: absolute;
		top: 1.5px;
		left: 1.5px;
		height: 10px;
		width: 10px;
		content: '';
		display: block;
		background-color: $navy;
		border-radius: 2px;
		opacity: 0;
	}

	&:checked::after {
		opacity: 1;
	}
}

// ------ Radio ------
.ReactTable .rt-thead .rt-th input[type='radio'],
.ReactTable .rt-tbody .rt-td input[type='radio'] {
	position: relative;
	left: -3px;
	appearance: none;
	border: 1px solid $navy;
	border-radius: 50%;
	height: 16px;
	width: 16px;
	position: relative;
	cursor: pointer;

	&:focus {
		outline: none;
	}

	&::after {
		position: absolute;
		top: 1.5px;
		left: 1.5px;
		height: 10px;
		width: 10px;
		content: '';
		display: block;
		background-color: $navy;
		border-radius: 50%;
		opacity: 0;
	}

	&:checked::after {
		opacity: 1;
	}
}

// ------ Custom table ------
.custom-table {
	&__no_headers {
		padding: 2rem 0rem 2rem 3.5rem;

		.ReactTable .rt-thead.-header {
			display: none;
		}
	}
}

.ReactTable.-select .rt-th:first-child,
.ReactTable.-select .rt-td:first-child {
	text-overflow: initial;
}

.ReactTable.-striped .rt-tr {
	&.-active-device,
	&.-active-item {
		background: $blue-pastel;
	}
	&.-active-device-dark {
		background: $blue-pastel-dark;
	}
}

.table-img-preview {
	max-width: 60px;
	max-height: 60px;
	width: auto;
	height: auto;

	&__logo {
		@extend .table-img-preview;
		background-color: $blue-dark;
		padding: 10px;
	}
}
