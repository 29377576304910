.filters {
	&__button {
		height: 3.2rem;
		font-size: 1.4rem;
		padding: 0 1.5rem;
		border-radius: 2px;
		&__active {
			color: $white;
			background-color: $green;
		}
	}
}
