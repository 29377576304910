// ----------------------- Heading -----------------------
.heading {
	// ********* Login *********
	&__login {
		color: $white !important;
		font-size: 1.3rem;
		color: $blue-dark;
		font-weight: 700;
		font-size: 2rem;
		line-height: 20px;
	}

	&__nav {
		font-weight: 300;
		font-size: 1.4rem;
		color: $white;
		margin-bottom: 0;
	}

	&__break {
		display: block;
		text-align: center;
		font-size: 1.5rem;
	}
}
