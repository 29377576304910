.sortable-sections {
	display: block;
	padding: 0;
	margin: 0 0 14px;

	overflow-y: auto;
	border: 1px solid #efefef;
	background: #f8f8f8;
	@media (min-width: 1170px) {
		max-width: 845px;
	}

	&::after {
		content: '';
		display: block;
		clear: both;
	}

	&.drag {
		.sortable-sections__li {
			&:hover {
				.item__delete {
					color: #c0c0c0;
				}
			}

			.item__move {
				cursor: default;
			}
		}
	}
}

.sortable-sections__li {
	list-style: none;
	padding: 0;
	user-select: none;

	&:last-of-type {
		.item {
			border-bottom: none;
		}
	}

	&.drag {
		.item {
			cursor: row-resize;
			border-bottom: 1px solid #efefef;
			border-top: 1px solid #efefef;
			padding: 0;
		}
		.item__move {
			color: #212121;
		}
	}

	.item {
		display: flex;
		position: relative;
		align-items: center;
		min-height: 36px;
		border-bottom: 1px solid #efefef;
		padding: 1px 0 0;
		background: #fff;
		font-size: 1.3rem;
		line-height: 1.3;
	}

	.item__move {
		display: block;
		flex: 0 0 30px;
		max-width: 30px;
		line-height: 30px;
		color: #c0c0c0;
		cursor: row-resize;
		text-align: center;
		transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

		&:hover {
			color: #212121;
		}

		i {
			font-size: 14px;
		}
	}

	.item__title {
		display: block;
		flex: 1 1 auto;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		color: #212121;
	}

	.item__delete {
		display: block;
		flex: 0 0 30px;
		max-width: 30px;
		transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		color: #c0c0c0;
		text-align: center;
		cursor: pointer;
		padding: 0;
		border: none;
		outline: none;

		&:hover {
			color: #ff4d4f;
		}

		i {
			font-size: 17px;
		}
	}
}
