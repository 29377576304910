.material_player {
	overflow: hidden;

	.atdsplayer {
		float: none !important;
	}

	.atdsplayer-container {
		float: none !important;
		width: 100% !important;
		height: auto !important;
	}
}
