// ********------------ Navbar------------********

body .navbar-static-side {
	transition: all 0.2s ease-in-out;
}

.navbar-static-side {
	z-index: 500;
}

.navbar-static-top {
	width: 100%;
	padding: 1.5rem 2rem;
	display: flex;
}

.navbar-buttons {
	margin-left: auto;
}
