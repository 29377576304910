.table {
	&__status {
		font-weight: bold;
		&--active {
			color: $green;
		}

		&--inactive {
			color: $red;
		}
	}
}
