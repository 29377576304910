.ant-alert-error {
	background-color: $red;
	border: 1px solid $red;
}

.ant-alert-with-description .ant-alert-message,
.ant-alert-description,
.ant-alert-error .ant-alert-icon {
	color: white;
}
