.not_auth {
	&__container {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		height: 80vh;
	}
	&__text {
		font-weight: 900;
	}
	&__icon {
		font-size: 10rem;
	}
}
