.search {
	&__input {
		position: relative;
		height: 3.3rem;
	}

	&__clear {
		position: absolute;
		top: 0.1rem;
		right: 0.1rem;
		height: 88%;
		width: 4rem;
		background-color: $gray-white-light;
		padding: 0rem;
		@include flexCenter;
	}

	&__select {
		height: 3.3rem;
	}

	&__container {
		display: flex;
		justify-content: space-between;
	}
}
