.tabs-breadcrumbs {
	&__container {
		height: 35px;
	}
	&__name {
		display: block;
		font-weight: 900;
		font-size: 1.5rem;
		color: $gray-medium;
		height: 15px;
		margin: 5px 5px 15px 5px;
	}
	&__link {
		display: block;
		font-weight: 900;
		font-size: 1.5rem;
		color: $gray-medium;
		height: 15px;
		margin: 5px 5px 15px 5px;
		&:hover {
			color: $navy-hover;
		}
	}
	&__skeleton {
		height: 15px;
		margin: 5px 5px 15px 5px;
	}
	&__web-link {
		color: $navy-hover;
		font-size: 1.2rem;
		margin: 5px 5px 5px 5px;
		&:hover {
			color: $gray-medium;
		}
	}
}
