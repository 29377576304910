@keyframes fadeIn {
	0% {
		opacity: 0;
		top: 10%;
	}
	100% {
		opacity: 1;
		top: 15%;
	}
}
@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
