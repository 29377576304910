.info {
	position: relative;
	padding-right: 20px;

	.row.form-group {
		margin-right: -35px;

		> *:nth-child(2) {
			padding-right: 35px;
		}
	}
}

.info__icon {
	position: absolute;
	right: 0;
	top: 0;
	color: #1890ff;
	cursor: pointer;
}
