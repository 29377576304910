.logo {
	@include flexCenter;
	align-items: flex-end;
	color: $white;
	font-size: 2rem;
	font-weight: 300;

	&__name {
		padding-bottom: 0.3rem;
	}

	&__square {
		background-color: $navy;
		padding: 12px 2px 5px 17px;
		font-weight: 400;
	}
}

.heading {
	&__login {
		span {
			margin-top: 15px;
		}
	}
}
