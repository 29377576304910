.ant-tabs {
	overflow: visible;
}

.ant-tabs-nav .ant-tabs-tab {
	margin: 0;
}

.ant-tabs-bar {
	background-color: $white;
}

.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:hover {
	color: $navy;
}

.ant-tabs-ink-bar {
	background-color: $navy;
}
