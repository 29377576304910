// --------------- React widgets multiselect ---------------

.rw-multiselect .rw-input-reset {
	padding: 0 !important;
}
.react-widget-select {
	.rw-widget-input {
		color: $gray-dark;
		font-weight: 400;
		min-height: 4rem;
		border-radius: 4px;
		font-size: 1.3rem;
		background-color: $gray-white-light !important;
		border: 1px solid #e5e6e7;

		input {
			&::placeholder {
				color: $gray-light;
				font-weight: bold;
				font-size: 1.2rem;
				padding-left: -20px;
			}
		}
	}

	.rw-widget-picker {
		height: auto;
		display: flex !important;
	}

	.rw-widget-input,
	.rw-filter-input {
		box-shadow: none !important;
	}

	.rw-widget-input {
		padding: 0.4rem 1rem;
	}

	.rw-state-focus {
		border: 1px solid $navy;
	}

	.rw-multiselect-tag {
		background: $navy;
		color: $white;
	}
}
