.custom-control-label {
	cursor: pointer;
	margin-left: 1rem;
}

.custom-control-label::before,
.custom-control-label::after {
	height: 1.4rem;
	width: 1.4rem;
	@include flexCenter;
	left: -2.2rem;
}
