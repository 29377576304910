.links-list {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 16px;
	background: $white;

	a {
		display: block;
		flex: 0 0 auto;
		padding: 12px 16px;
		position: relative;
		color: rgba(0, 0, 0, 0.65);
		font-size: 14px;
		line-height: 1.5;
		transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

		&:after {
			display: none;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			height: 2px;
			background: $navy;
		}

		&:hover {
			color: $navy-hover;
		}

		&.active {
			&:after {
				display: block;
			}
		}
	}
}
