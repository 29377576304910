.video-container {
	position: relative;
	height: 100%;
	width: 100%;
}

.shaka-video {
	width: 100%;
	height: 100%;
	display: block;
	background: black;
}
